<template>
  <div class="grid">
    <div v-html="getMainContent(collection)" class="collection__main-content col-12 col-m-5" />

    <div class="col-m-2"></div>
    <div class="collection__main-content-video col-12 col-m-5" v-if="hasVideo(collection)">
      <SfVideo
        class="collection__video desktop-only"
        :src="getDesktopVideo(collection)"
        :poster="getDesktopVideoPoster(collection)"
      />
      <SfVideo
        class="collection__video mobile-only"
        :src="getMobileVideo(collection)"
        :poster="getMobileVideoPoster(collection)"
      />
    </div>
  </div>
</template>

<script type="ts">
import { defineComponent } from '@nuxtjs/composition-api';
import { SfVideo } from '~/components';
import collectionGetters from '~/modules/collections/getters/collectionGetters';

export default defineComponent({
  components: {
    SfVideo,
  },
  props: {
    collection: {
      type: Object,
      default: null,
    },
  },
  setup() {

    return {
      ...collectionGetters
    };
  }
});
</script>

<style lang="scss" scoped>
.collection {

  &__item-image {
    @include for-mobile {
      margin: var(--sp_32) 0;
    }
  }

  &__main {

    @include for-desktop {
      margin-bottom: var(--sp_40);
    }

    &-content {
      display: flex;
      flex-direction: column;

      ::v-deep {

        .collection__main-content-image {
          margin: var(--sp_8) 0 var(--sp_32);
        }

        p {
          font-size: var(--font-size--base);
          line-height: var(--line-height--base);
          margin: 0 0 var(--sp_24);

          @include for-desktop {
            font-size: var(--font-size--lg);
            line-height: var(--line-height--lg);
          }
        }

        img {
          display: block;
          width: 100%;
          max-width: 100%;
        }

        .bottom {
          margin-top: auto;
        }

        .right {
          margin-left: auto;
        }
      }
    }

    &-content-video {
      @include for-mobile {
        margin: var(--sp_8) 0 var(--sp_8);
      }
    }
  }
}
</style>
